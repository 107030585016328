import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@sus-core/components/scaffolding/Layout';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';

type CMSPageProps = SusPageProps<GatsbyTypes.CmsPageQuery>;
export default function CMSPage({ data, location, pageContext }: CMSPageProps) {
  const cmsPage = data.sus.cmsPage;
  const {
    meta_description,
    meta_keywords,
    meta_title,
    content,
    content_heading,
  } = cmsPage;

  return (
    <PageProvider
      data={{
        pageType: 'CMS',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{
          title: meta_title || content_heading,
          description: meta_description,
          keywords: meta_keywords,
        }}
        currentPageName={content_heading}>
        {content_heading && (
          <h1 className="text-center mb-16">{content_heading}</h1>
        )}
        <div className={PAGE_CONTAINER_CSS}>
          <section
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </Layout>
    </PageProvider>
  );
}

export const query = graphql`
  query CmsPage($id: Int!) {
    sus {
      cmsPage(id: $id) {
        content
        identifier
        content_heading
        meta_description
        meta_keywords
        meta_title
        page_layout
        title
        url_key
      }
    }
  }
`;
